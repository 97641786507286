// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markets-js": () => import("./../../../src/pages/markets.js" /* webpackChunkName: "component---src-pages-markets-js" */),
  "component---src-pages-services-asbestos-removal-js": () => import("./../../../src/pages/services/asbestos-removal.js" /* webpackChunkName: "component---src-pages-services-asbestos-removal-js" */),
  "component---src-pages-services-demolition-js": () => import("./../../../src/pages/services/demolition.js" /* webpackChunkName: "component---src-pages-services-demolition-js" */),
  "component---src-pages-services-hazardous-waste-disposal-js": () => import("./../../../src/pages/services/hazardous-waste-disposal.js" /* webpackChunkName: "component---src-pages-services-hazardous-waste-disposal-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blogListTemplate.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-projects-list-template-js": () => import("./../../../src/templates/projectsListTemplate.js" /* webpackChunkName: "component---src-templates-projects-list-template-js" */)
}

